.AppLogo {
    padding: 2px 0 0;
    height: 48px;
    display: flex;
    align-items: center;

    img {
        height: 26px;
    }
}

@hack: true; @import "/src/src/lintasarta-theme.less";