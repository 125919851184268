.AppConfirmDialog {
    cursor: pointer;
}

.AppConfirmDialogDisabled {
    cursor: not-allowed;
    color: @ccxGray;
}

.AppConfirmDialogCritical {
    cursor: pointer;
    color: @ccxRed;
}

.AppConfirmDialogAlertMessage {
    margin-left: 35px !important;
}

.AppConfirmDialogAlertDescription {
    margin-left: 35px !important;
}
.AppConfirmationInput {
    border-radius: 8px;
}

@hack: true; @import "/src/src/lintasarta-theme.less";