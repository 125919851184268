.ServiceConnectionInformation {
    width: 100%;
    padding: 8px;
}

.ServiceConnectionInformationCode {
    background: transparent;
    color: @ccxBlack;
    font-size: 12px;
    line-height: 20px;
    border: transparent;
}

.ServiceConnectionInformationCodeContent {
    display: flex;
    flex-wrap: wrap;
}

.ServiceConnectionInformationCodeSubContentText {
    white-space: nowrap;
    display: inline-flex;
}

@hack: true; @import "/src/src/lintasarta-theme.less";