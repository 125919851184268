.BackupScheduleModal {
    :global(.ant-radio-button-wrapper:first-child) {
        border-radius: 8px 0 0 8px !important;
    }
    :global(.ant-radio-group-outline > *:last-child) {
        border-radius: 0 8px 8px 0 !important;
    }
    :global(.ant-input-number) {
        border-radius: 8px !important;
    }
    :global(.CronInput-time-picker) {
        border-radius: 8px !important;
    }
}

@hack: true; @import "/src/src/lintasarta-theme.less";