.InstanceVolumeTypeFormCentered {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    margin-top: 2px;
    height: fit-content;
}
.InstanceVolumeTypeFormInputNumber {
    width: 60%;
    border-radius: @border-radius-base-x2;
}

@hack: true; @import "/src/src/lintasarta-theme.less";