.AppFormTag {
    font-size: 16px;
}

.AppFormTagSmall {
    font-size: 12px;
}
.AppTagRadius {
    border-radius: 4px;
}

@hack: true; @import "/src/src/lintasarta-theme.less";