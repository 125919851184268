@app-bg-text-color: @text-color-secondary;

.ant-modal-header {
    border-radius: 8px 8px 0 0;
}

.ant-modal-content {
    border-radius: 8px;
}

.ant-message-notice-content {
    border-radius: 8px;
}
