.NoChartAvailable {
    display: flex;
    align-content: space-around;
    flex-direction: column;
    gap: 4px;
    opacity: 0.65;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    span {
        font-size: 20px;
    }
}

@hack: true; @import "/src/src/lintasarta-theme.less";