.SecretText {
    cursor: pointer;
    display: inline-grid;
    color: @ccxLightBlueColor;
}

.SecretText:hover {
    color: @ccxRed;
}

.SecretTextDisplay {
    display: inline-block;
}

@hack: true; @import "/src/src/lintasarta-theme.less";