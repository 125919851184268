.AdminTables {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    h1 {
        margin: 0 0 16px 2px;
        line-height: 22px;
        font-size: 14px;
    }
}

.AdminTablesTabs > *:last-child {
    background: @ccxWhite;
    padding: 24px;
}

.AdminTablesOperationButton {
    margin-bottom: @margin-xs;
}

@hack: true; @import "/src/src/lintasarta-theme.less";