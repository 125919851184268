.CopyToClipboardText {
    cursor: pointer;
}
.CopyToClipboardTextNowrap {
    white-space: nowrap;
}

.CopyToClipboardText:hover {
    color: @ccxAltBlue;
}

.CopyToClipboardIcon {
    margin-left: 10px;
    opacity: 0.4;
}

@hack: true; @import "/src/src/lintasarta-theme.less";