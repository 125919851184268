.WizardFormConfigurationStep4Group {
    width: 100%;
    margin-top: 10px;
}

.WizardFormConfigurationStep4RadioItem {
    width: 100%;
    height: 90px;
    border-radius: @border-radius-base-x2 !important;
}

.WizardFormConfigurationStep4RadioTypeItem {
    width: 100%;
    height: 40px;
    border-radius: @border-radius-base-x2 !important;
    margin: 4px;
}

.WizardFormConfigurationStep4Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    > small {
        align-self: end;
    }

    > :nth-child(2) {
        font-size: 20px;
    }
}

.WizardFormConfigurationStep4NetworkTypeItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: @border-radius-base-x2 !important;
    > span {
        margin-top: 5px;
    }
}

.WizardFormConfigurationStep4VPC {
    width: 50% !important;
}

.WizardFormConfigurationStep4AZ {
    height: 40px !important;
    padding: 0 !important;
    margin: 0;
    align-items: center;
    .ant-card-body {
        padding: 0 !important;
    }
}
@media (min-width: 769px) {
    .StepsExtraSmallScreen {
        padding-top: 8px;
    }
}
@media (max-width: 500px) {
    .WizardFormConfigurationStep4VPC {
        width: 100% !important;
    }
    .WizardFormConfigurationStep4RadioTypeItem {
        width: 100% !important;
    }
}
.StepsExtraSmallScreen {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding-top: 16px;
}

@hack: true; @import "/src/src/lintasarta-theme.less";