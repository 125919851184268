.DataStoreOverviewQueriesPerSecond {
    border-radius: @border-radius-base-x2;
    border: 1px solid @ccxCardBorder;
    min-height: 190px;

    header {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        padding: 24px 24px 8px 24px;
        font-weight: bold;
    }

    main {
        padding: 0 24px 24px 24px;
        font-size: 12px;
        line-height: 20px;
        font-weight: normal;
        opacity: 0.85;
    }
}
@media (max-width: 400px) {
    .DataStoreOverviewQueriesPerSecond {
        header {
            padding: 12px 12px 8px 12px;
        }

        main {
            padding: 8px 12px 12px 12px;
        }
    }
}

@hack: true; @import "/src/src/lintasarta-theme.less";