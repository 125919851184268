.DatastoreScalingInfoCard {
    border-radius: @border-radius-base-x2;
    min-height: 6.25rem;
    background: #f5f5f5;
    padding: 1rem 1rem 0 1rem;
}

.DatastoreScalingInfoCardLogo {
    margin-top: 0.5625rem;
}

.DatastoreScalingInfoCardRow > :nth-child(2) {
    border-right: 1px solid rgba(0, 0, 0, 0.25);
    padding-right: 10px;
}

.DatastoreScalingInfoCardRow > :nth-child(3) {
    padding-left: 1rem;
}

.DatastoreScalingInfoCardName {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}
@media (max-width: 400px) {
    .DatastoreScalingInfoCard {
        padding: 10px 10px 0 10px;
    }
}

@hack: true; @import "/src/src/lintasarta-theme.less";