.Hoverable {
    cursor: pointer;
    display: inline-grid;
}

.Hoverable:hover {
    color: @ccxAltBlue;
}

.HoverableIcon {
    margin-left: 10px;
    opacity: 0.4;
    color: @ccxAltBlue;
}

@hack: true; @import "/src/src/lintasarta-theme.less";