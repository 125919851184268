.DataStoreSettingsProxySqlAdminPassword {
    h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.85;
    }
}

.DataStoreSettingsProxySqlAdminPasswordDescription {
    margin-bottom: 8px;
}

@hack: true; @import "/src/src/lintasarta-theme.less";