.DatastoreSettingDbParametersEditIcon {
    color: @primary-color;
    margin-left: 1rem;
    display: inline-flex;
    justify-content: flex-end;
}

.DatastoreSettingDbParametersName {
    margin-right: 0.3rem;
}

.DatastoreSettingDbParametersField {
    margin-bottom: 0;

    input,
    textarea {
        border-radius: @border-radius-base-x2;
    }
}

.DatastoreSettingDbParametersSelect {
    width: 10rem !important;
}

.DatastoreSettingDbParametersEdit {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DatastoreSettingDbParametersDefault {
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
}

@hack: true; @import "/src/src/lintasarta-theme.less";