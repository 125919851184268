.VpcCidr {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.VpcCidr > * {
    display: flex;
    flex-basis: '100%';
    flex-direction: row;
    overflow-x: hidden;
    gap: 8px;
    align-items: center;
}

@hack: true; @import "/src/src/lintasarta-theme.less";