.ServiceResourceslabel {
    font-weight: 700 !important;
}

.ServiceResourcesSection {
    display: flex;
    gap: 4px;
    align-items: center;
}
.ServiceResourcesDetail {
    margin-left: 4px;
}

.ServiceResourcesInsatnceSize {
    display: block;
}

.ServiceResourcesInstance {
    display: inline-flex;
}

.ServiceResourcesRow {
    display: block;
}

@hack: true; @import "/src/src/lintasarta-theme.less";