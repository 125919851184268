.UserMenu {
    padding: 0;
    color: @ccxHeaderText;
}

.UserMenuBadge {
    color: @ccxHeaderText;
}
@media (max-width: 550px) {
    .UserMenu {
        width: 100px;
    }
    .UserMenuName {
        width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@hack: true; @import "/src/src/lintasarta-theme.less";