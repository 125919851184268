.DbUsersCollapse {
    background-color: transparent;
}

.DbUsersCollapsePanel {
    border-bottom: 1px solid transparent;
}

.DrawerFooterButtonsLeft {
    text-align: right;
}

.DrawerFooterButtonsRight {
    text-align: left;
}

.DbUsersQuickNavAnchor {
    display: flex;
}

.DbUsersOptionsColumn {
    text-align: center;
}

.DbUserConnectionStringDescription {
    margin-top: 16px;
}

.DbUsersExpandableRowContent {
    -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
    -webkit-border-radius: @border-radius-base-x2;
    -moz-border-radius: @border-radius-base-x2;
    border-radius: @border-radius-base-x2;
}

.DbUsersStop {
    color: @ccxGray;
}

.DbUsersLabel {
    margin-bottom: 1rem;
    padding: 0 0 4px 16px;
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    border-bottom: 1px solid #d9d9d9;
    opacity: 0.85;
}

.DbUsersSegmentedData {
    display: flex;
}

.DbUsersSegmentedFirstItem {
    padding: 5px 8px 5px 10px;
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
    background-color: #f5f5f5;
    border: 1px solid #dbdbdb;
    border-right: none;
}

.DbUsersSegmentedSecondItem {
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    padding-right: 15px;
    border-right: 1px solid #dbdbdb;
    padding: 5px 10px;
    border: 1px solid #dbdbdb;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 120px;
    white-space: nowrap;
}

.DbUserPopoverCol {
    font-size: 12px;
    font-weight: 500;
}

.DbUserPopoverCol {
    ul {
        padding: 0 1rem !important;
    }
}
.ConnectionHeaderButtons {
    gap: 8px;
    display: flex;
}

@media (max-width: 450px) {
    .ConnectionHeaderButtons {
        gap: 8px;
        display: grid;
    }
}

@hack: true; @import "/src/src/lintasarta-theme.less";