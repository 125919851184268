.AppHeaderNotifications {
    color: @ccxHeaderText;
}

.AppHeaderNotificationsBadge {
    color: @ccxHeaderText;
}

.AppHeaderNotificationsTitle {
    text-align: center;
}

.AppHeaderNotificationsDivider {
    margin: 8px 0;
    width: 80%;
}

.AppHeaderNotificationsMenu {
    padding: 4px 16px;
}

@hack: true; @import "/src/src/lintasarta-theme.less";