.AdminUsers {
    padding: @padding-lg;
    background-color: @white;
}

@media (max-width: 1220px) {
    .AdminUsers {
        width: calc(100vw - 30px);
        overflow-x: scroll;
    }

    .AppGridTable {
        min-width: 950px;
    }
}

@hack: true; @import "/src/src/lintasarta-theme.less";