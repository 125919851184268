.AddServiceCreateVPCStepContainer {
    margin: 20px;

    h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: @ccxBlack;
        text-align: center;
        margin-bottom: 30px;
    }

    @media (max-width: 768px) {
        :global(.ant-steps-vertical) {
            display: inline-block;
            width: 100% !important;
        }
        :global(.ant-steps-vertical > .ant-steps-item) {
            display: inline-block !important;
        }
        :global(.Wizard.Wizard--vertical-steps) {
            display: inline;
        }
        :global(.Wizard.Wizard--vertical-steps .Wizard-step-header) {
            border: none;
            display: block;
        }
        :global(.ant-steps .ant-steps-vertical) {
            width: 100% !important;
        }
    }
}
.AddServiceCreateVPCConfiguration {
    width: 50%;
}

.AddServiceCreateVPCStepDivider {
    margin-bottom: 0;
}

.AddServiceCreateVPCStepCol {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.AddServiceCreateVPCStepRow {
    margin-bottom: 5px;
}

.AddServiceCreateVPCStepFlag {
    margin-right: 5px;
}
@media (max-width: 768px) {
    .AddServiceCreateVPCConfiguration {
        width: 100%;
    }
    .AddServiceCreateVPCStepCol {
        justify-content: flex-start;
    }    
}

@hack: true; @import "/src/src/lintasarta-theme.less";