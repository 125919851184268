.VpcPeerings {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.VpcPeerings > * {
    display: flex;
    flex-basis: '100%';
    flex-direction: row;
    overflow-x: hidden;
    gap: 8px;
    align-items: center;
    font-size: 12px;
    opacity: 0.65;
    line-height: 20px;
}

@hack: true; @import "/src/src/lintasarta-theme.less";