.AdminDataStores {
    padding: @padding-lg;
    background-color: @white;
}

.AdminDataStoresClusterId {
    display: flex;
    justify-content: space-between;
    button {
        padding: 0;
    }
}

.AdminDataStoresClusterName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 10rem;
    font-size: medium;
    font-weight: 500;
}

.AdminDataStoresButton {
    span {
        width: 10rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 1220px) {
    .AdminDataStores {
        width: calc(100vw - 30px);
        overflow-x: scroll;
    }

    .AppGridTable {
        min-width: 950px;
    }
}

@hack: true; @import "/src/src/lintasarta-theme.less";