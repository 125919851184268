.Error404Base {
    background: @ccxWhite;
    border-radius: @border-radius-base-x2;
    justify-content: center;
    align-items: center;
    gap: 48px;
    width: 100%;
    padding: 50px;
    padding-top: 0;
    margin-top: 20px;
}

.Error404Details {
    width: 380px;
    text-align: center;

    .Error404DetailsTitle {
        font-size: 38px;
        font-weight: 500;
        line-height: 46px;
    }
}

@hack: true; @import "/src/src/lintasarta-theme.less";