.PaymentsInvoicesStatus {
    display: flex;
    align-items: center;
    justify-content: stretch;
}

.PaymentsInvoicesStatus > *:nth-child(1) {
    margin-right: 5px;
}

.PaymentsInvoicesPaymentHistory {
    margin-top: 30px !important;
}

.PaymentsInvoicesStatusDot {
    width: 6px;
    height: 6px;
    border-radius: @border-radius-base-50;
}

.PaymentsInvoicesStatusDotGreen:extend(.PaymentsInvoicesStatusDot) {
    background-color: @ccxGreen;
}

.PaymentsInvoicesStatusDotOrange:extend(.PaymentsInvoicesStatusDot) {
    background-color: @ccxOrange;
}

.PaymentsInvoicesStatusDotRed:extend(.PaymentsInvoicesStatusDot) {
    background-color: @ccxRed;
}

.PaymentsInvoicesStatusDotBlack:extend(.PaymentsInvoicesStatusDot) {
    background-color: @ccxBlack;
}

.PaymentsInvoicesStatusDotGreenGlow {
    box-shadow: 0 0 10px @ccxGreen;
    transition: box-shadow 0.3s ease-in-out;
}

.PaymentsInvoicesStatusDotRedGlow {
    box-shadow: 0 0 10px @ccxRed;
    transition: box-shadow 0.3s ease-in-out;
}

.PaymentsInvoicesHeading {
    display: flex;
    justify-content: space-between;
}

@hack: true; @import "/src/src/lintasarta-theme.less";