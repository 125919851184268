.AppButton_text_middle {
}

.AppButton_text_large {
}

.AppButton_text_small {
    font-size: @font-size-sm;
}

@hack: true; @import "/src/src/lintasarta-theme.less";