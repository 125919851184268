.CurrentBillingPeriod {
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 0;
}

.CurrentBillingPeriod > * {
    display: flex;
    flex-basis: 100%;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.CurrentBillingPeriod > *:nth-child(1) {
    font-size: 12px;
    gap: 0;
}

.CurrentBillingPeriod > *:nth-child(2) {
    font-size: 12px;
    gap: 0;
}

@hack: true; @import "/src/src/lintasarta-theme.less";