.AppLabeledIconStatus {
    display: flex;
    flex-direction: row;
}

.AppLabeledIconStatusIcon {
    order: 1;
}

.AppLabeledIconStatusLabel {
    order: 2;
    font-size: 14px;
    line-height: 22px;
}

@hack: true; @import "/src/src/lintasarta-theme.less";