.BackupActionCreateDatastoreButton {
    color: black;
}
.PopoverContainer {
    width: 250px;
}
.PopoverContentRow {
    align-items: center;
}
.PopoverActions {
    justify-content: flex-end;
    margin-top: 4px;
}
.BorderRadius {
    border-radius: 4px;
}
.ExclamationCircleFilledColor {
    color: #ffac0a;
}

@hack: true; @import "/src/src/lintasarta-theme.less";