.AppNotificationItem {
    display: flex;

    small {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
    }
}

.AppNotificationItemMessage {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}

.AppNotificationItemStateIconOk {
    font-size: 21px;
    color: @ccxStatusOk;
}

.AppNotificationItemStateIconError {
    font-size: 21px;
    color: @ccxStatusError;
}

.AppNotificationItemStateIconRunning {
    font-size: 21px;
    color: @ccxStatusWarning;
}

@hack: true; @import "/src/src/lintasarta-theme.less";