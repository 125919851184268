.WizardFormConfigurationStep5Col {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.WizardFormConfigurationStep5Row {
    margin-bottom: 5px;
}

strong {
    margin-right: 4px;
}

.WizardFormConfigurationStep5Flag {
    margin-right: 5px;
}

@media (max-width: 768px) {
    .WizardFormConfigurationStep5Col {
        justify-content: flex-start;
    }
}
@media (min-width: 769px) {
    .StepsExtraSmallScreen {
        display: none;
    }
}
.StepsExtraSmallScreen {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    padding-top: 16px;
}

@hack: true; @import "/src/src/lintasarta-theme.less";