.DataStoreOverviewCloudInformation {
    border-radius: @border-radius-base-x2;
    border: 1px solid @ccxCardBorder;
    min-height: 142px;

    header {
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: space-between;
        padding: 24px 24px 8px 24px;
        font-weight: bold;
    }

    main {
        padding: 0 24px 24px 24px;
        font-size: 12px;
        line-height: 20px;
        font-weight: normal;
        opacity: 0.85;
    }
}

.DataStoreOverviewCloudInformationColumns > * {
    border-right: 1px solid @ccxCardColumnDivider;
    padding: 0 16px;
    min-height: 60px;
}

.DataStoreOverviewCloudInformationColumns > *:first-child {
    padding-left: 0;
}

.DataStoreOverviewCloudInformationColumns > *:last-child {
    border-right: none;
    padding-right: 0;
}

.DataStoreOverviewCloudInformationLabel {
    opacity: 0.65;
}

.DataStoreOverviewCloudInformationValue {
    opacity: 0.85;
}
@media (max-width: 400px) {
    .DataStoreOverviewCloudInformation {
        header {
            padding: 12px 12px 8px 12px;
        }

        main {
            padding: 8px 12px 12px 12px;
        }
    }
    .DataStoreOverviewCloudInformationColumns > * {
        padding: 0 8px;
    }
}

@hack: true; @import "/src/src/lintasarta-theme.less";