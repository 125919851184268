.ChangePaymentMethod {
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 0;
    align-items: flex-start;
}

.ChangePaymentMethod > * {
    display: flex;
    flex-basis: 100%;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.ChangePaymentMethod > *:nth-child(2) {
    font-size: 14px;
    margin-left: 16px;
}

@hack: true; @import "/src/src/lintasarta-theme.less";