.TextFormatBreakWord {
    word-break: break-word;
}

.TextFormatBold {
    font-weight: 500;
}

.TextFormatMuted {
    color: lighten(@ccxBlack, 30%);
}

.TextFormatInfo {
    color: @ccxBlack;
}

.TextFormatSuccess {
    color: @ccxStatusOk;
}

.TextFormatWarning {
    color: @ccxStatusWarning;
}

.TextFormatDanger,
.TextFormatError {
    color: @ccxStatusError !important;
}

@hack: true; @import "/src/src/lintasarta-theme.less";