// @todo move these styles to subcomponents it's duplicated with WizardFormConfigurationStep3 at the moment
.InstanceVolumeTypeFormCentered {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    margin-top: 2px;
    height: fit-content;
}

.InstanceVolumeTypeFormInputNumber {
    width: 50%;
    border-radius: @border-radius-base-x2;
}

.InstanceVolumeTypeFormAlertBox {
    margin-bottom: 1rem;
}

@hack: true; @import "/src/src/lintasarta-theme.less";