.Account {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    h1 {
        margin: 0 0 16px 2px;
        line-height: 22px;
        font-size: 14px;
    }
}

.AccountTabs > *:last-child {
    background: @ccxWhite;
    padding: 24px;
}
@media (max-width: 1220px) {
    .Account {
        width: calc(100vw - 40px);
    }
}

@hack: true; @import "/src/src/lintasarta-theme.less";