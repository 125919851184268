.WizardSelectTypeStep-select {
    /* override component styles */
    .BoxSelect_item-wrapper,
    .BoxSelect_item-wrapper--active {
        border-radius: @border-radius-base-x2;

        &:hover:not(.BoxSelect_item-wrapper--disabled) {
            box-shadow: none;
            border: 1px solid @primary-color;
        }
    }
}

.WizardSelectTypeStep-select_item {
    padding: @padding-md;
}

.WizardSelectTypeStep-detail_wrapper {
    border: @border-width-base @border-style-base @border-color-base;
    border-radius: @border-radius-base-x2;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.WizardSelectTypeStep-detail {
    height: 100%;
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}
.WizardSelectTypeStep-detail:before {
    content: '';
    pointer-events: none;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-repeat: no-repeat;
    background-position: left -150px center;
    background-size: 400px;
    mask-size: 400px;
    mask-repeat: no-repeat;
    mask-position: left -150px center;
}

.WizardSelectTypeStep--DefaultIcon {
    color: @info-color;
}

@hack: true; @import "/src/src/lintasarta-theme.less";