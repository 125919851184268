.DatePickerContainer {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.HistoryOutlinedIcon {
    padding: 0 10px;
}

.DatePicker {
    border: none;
    padding-right: 0px;
}

.DbGrowthChartHeading {
    font-size: 16px;
    font-weight: bold;
}

.DbGrowthChartHeader {
    padding: 0px 0px 20px 0px;
}

.ChartTip {
    font-size: 10px;
    text-align: left;
}

@hack: true; @import "/src/src/lintasarta-theme.less";