.AppHeader {
    background-color: @ccxSecondaryBackground;
    padding: 0 32px 0 32px;
    z-index: 1;
    height: 48px;
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    align-content: stretch;
    gap: 32px;
}

.AppHeader > *:nth-child(1) {
    height: 48px;
    display: flex;
    align-items: center;
}

.AppHeader > *:nth-child(2) {
    text-align: right;
    height: 48px;
    display: flex;
    align-items: center;
    gap: 32px;
}

@media (max-width: 400px) {
    .AppHeader {
        padding: 0 16px 0 16px;
        gap: 16px;
    }

    .AppHeader > *:nth-child(2) {
        gap: 16px;
    }
}

@hack: true; @import "/src/src/lintasarta-theme.less";