.BackupsModalSettings {
    margin-top: 40px;
}

.BackupsModalSwitchText {
    margin-top: 5px;
}

.BackupsModalDatePicker {
    width: 100%;
}

@hack: true; @import "/src/src/lintasarta-theme.less";